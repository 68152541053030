import axiosInstance from "@/store/axios.instance";
import { sub } from "date-fns";

export default {
    namespaced: true,
    state() {
        return {
            invoiceConfig: null,
            nextNumbers: null
        }
    },
    mutations: {
        setInvoiceConfig(state, invoiceConfig) {
            state.invoiceConfig = invoiceConfig
        },
        setNextNumbers(state, nextNumbers) {
            state.nextNumbers = nextNumbers
        }
    },
    actions: {
        fetchInvoiceConfig({ commit, rootGetters }, suborganizationIds) {
            return axiosInstance.get('/invoice-config', {...rootGetters["authModule/getUrlConfig"], params: {suborganizationIds}})
                .then(result => {
                    commit('setInvoiceConfig', result?.data)
                    return result?.data
                })
        },
        updateInvoiceConfig({ commit, rootGetters }, {invoiceConfig, suborganizationId}) {
            return axiosInstance.put('/invoice-config', invoiceConfig, {...rootGetters["authModule/getUrlConfig"], params: {suborganizationId}})
                .then(result => {
                    commit('setInvoiceConfig', result?.data)
                    return result?.data
                })
        },
        fetchNextNumbers({ commit, rootGetters }, invoiceConfig) {
            return axiosInstance.post('/invoice-config/next-numbers', invoiceConfig, rootGetters["authModule/getUrlConfig"])
                .then(result => {
                    commit('setNextNumbers', result?.data)
                    return result?.data
                })
        },
        fetchSubInvoiceConfigs({ commit, rootGetters }) {
            return axiosInstance.get('/invoice-config', rootGetters["authModule/getUrlConfig"])
                .then(result => {
                    commit('setInvoiceConfig', result?.data)
                    return result?.data
                })
        },
        deleteSubInvoiceConfig({rootGetters}, suborganizationId) {
            return axiosInstance.delete(`/invoice-config/sub/${suborganizationId}`, rootGetters["authModule/getUrlConfig"])
        }
    },
    getters: {
        getInvoiceConfig(state) {
            return state.invoiceConfig;
        },
        getNextNumbers(state) {
            return state.nextNumbers;
        }

    }

}