import {OTLPTraceExporter} from '@opentelemetry/exporter-trace-otlp-http';
import {BatchSpanProcessor, WebTracerProvider} from '@opentelemetry/sdk-trace-web';
import {ZoneContextManager} from '@opentelemetry/context-zone';
import {Resource} from '@opentelemetry/resources';
import {SemanticResourceAttributes} from '@opentelemetry/semantic-conventions';
import {registerInstrumentations} from '@opentelemetry/instrumentation';
import {getWebAutoInstrumentations} from "@opentelemetry/auto-instrumentations-web";
import store from "@/store";
import {SpanStatusCode, trace} from "@opentelemetry/api";

// only enable configuration when API KEY is been given (to prevent console errors on local development)
if (process.env.VUE_APP_HONEYCOMB_API_KEY) {

    // honeycomb tracing configuration - https://docs.honeycomb.io/getting-data-in/opentelemetry/browser-js/
    const exporter = new OTLPTraceExporter({
        url: 'https://api.honeycomb.io/v1/traces',
        headers: {
            "x-honeycomb-team": process.env.VUE_APP_HONEYCOMB_API_KEY,
        },
    });
    const provider = new WebTracerProvider({
        resource: new Resource({
            [SemanticResourceAttributes.SERVICE_NAME]: 'buildbase-ui',
        }),
    });
    provider.addSpanProcessor(new BatchSpanProcessor(exporter));
    // add span to the console output - usefull when debugging
    // provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));
    provider.register({
        contextManager: new ZoneContextManager()
    });

    const backendUrl = process.env.VUE_APP_BACKEND_URL;
    registerInstrumentations({
        instrumentations: [
            getWebAutoInstrumentations({
                // load custom configuration for xml-http-request instrumentation
                '@opentelemetry/instrumentation-xml-http-request': {
                    propagateTraceHeaderCorsUrls: [
                        new RegExp(`${backendUrl}/.+`, 'g'),
                    ],
                    applyCustomAttributesOnSpan(span) {
                        addDefaultSpanAttributes(span)
                    }
                },
                '@opentelemetry/instrumentation-fetch': {
                    propagateTraceHeaderCorsUrls: [
                        new RegExp(`${backendUrl}/.+`, 'g'),
                    ]
                },
                "@opentelemetry/instrumentation-user-interaction": {
                    enabled: false
                },
                // Following instrumentation can be enabled if document load wants to be traced.
                // 08/10/2024: Currently disabled as this pushes us over the Honeycomb 20M free requests.
                // Also causes delays in Honeycomb data receiving and processing (due to free tier).
                "@opentelemetry/instrumentation-document-load": {
                    enabled: false
                    // applyCustomAttributesOnSpan(span) {
                    //     addDefaultSpanAttributes(span)
                    // }
                }
            })
        ],
    });

}

export function addDefaultSpanAttributes(span) {
    span.setAttribute("window.location.href", window.location.href);
    span.setAttribute('document.title', document.title);
    span.setAttribute('userId', store.getters["authModule/getUserId"])
    span.setAttribute('organizationId', store.getters["authModule/getOrganizationId"])
}

export function traceError(err, info) {
    const tracer = trace.getTracerProvider().getTracer("buildbase-vue-errors");
    const span = tracer.startSpan("vue-error");
    addDefaultSpanAttributes(span)
    span.setAttribute("error", err);
    span.setAttribute("info", info);
    span.setStatus({
        code: SpanStatusCode.ERROR,
        message: err.message,
    })
    span.recordException(err)
    span.end();

    // Navigation cancelled errors happen when spam clicking the menu or other navigation buttons 
    // Especially when the org is on trial (extra status requests make it slower).
    // In this case, the error we don't stop the error from being thrown, but it does not need to be printed in console
    if (!err.message.startsWith("Navigation cancelled from ")) {
      console.log(err)
    }
}

export function createTrace(tracerName, startSpan, data) {
    const tracer = trace.getTracerProvider().getTracer(tracerName)
    const span = tracer.startSpan(startSpan)
    addDefaultSpanAttributes(span)
    for (const [key, value] of Object.entries(data)) {
        span.setAttribute(key, value)
    }
    span.end();
}