export default {
    ABOUT: 'Over',
    CALENDAR: 'Kalender',
    CALENDAR_DETAIL_EDIT_ABSENCE:'Bewerk afwezigheid',
    CALENDAR_DETAIL_EDIT_HOURS:'Bewerk werkuren',
    CALENDAR_DETAIL_USER_EDIT_ABSENCE:'Bewerk werknemer afwezigheid',
    CALENDAR_TEAM_DETAIL_USER_EDIT_ABSENCE:'Bewerk teamlid afwezigheid',
    CALENDAR_DETAIL_USER_EDIT_HOURS:'Bewerk werknemer werkuren',
    CALENDAR_DETAIL:'Inklokken - detail',
    CALENDAR_TEAM_DETAIL_USER_EDIT_HOURS:'Bewerk teamlid werkuren',
    PRICKING: 'Inklokken',
    SETTINGS_ORGANIZATION_PARENT: "Organisatie",
    ORGANIZATION_DETAIL:'Organisatie detail',
    ORGANIZATION_DETAIL_EDIT_ORGANIZATION_NAME: "Organisatie - bewerk naam",
    ORGANIZATION_DETAIL_EDIT_LOGO:"Organisatie - bewerk logo",
    ORGANIZATION_DETAIL_EDIT_ORGANIZATION_INFORMATION: "Bewerk - organisatie informatie",
    CLIENT_CREATE: 'Klant aanmaken',
    CLIENT_DETAIL: 'Klant detail',
    CLIENT_OVERVIEW: 'Klanten',
    CLIENT_DETAIL_EDIT_CLIENT_NAME: "Klant - bewerk klantnaam",
    CLIENT_DETAIL_EDIT_CLIENT_INFORMATION: "Klant - bewerk klant informatie",
    CLIENT_DETAIL_EDIT_CONTACT_INFORMATION:"Klant - bewerk contact informatie",
    CLIENT_DETAIL_EDIT_FUNCTIONAL_ID:"Klant - bewerk klant id",
    CREATE_WORK_SCHEDULE: 'Nieuw werkrooster',
    DAY: 'Dag',
    DENIED: 'Geweigerd',
    EULA: 'EULA',
    CONTACT: 'Contact',
    FORGOT_PASSWORD: 'Wachtwoord vergeten',
    HOUR_CONSULTATION_OVERVIEW: 'Rapporten',
    INVOICE_OVERVIEW: 'Facturen',
    INVOICE_CREATE: 'Factuur aanmaken',
    INVOICE_EDIT: 'Factuur bewerken',
    INVOICE_DETAIL: 'Factuur detail',
    INVOICE_SEND: 'Verstuur factuur',
    LOGIN: 'Log in',
    LOGOUT: 'Uitloggen',
    MODIFY_USER_HOURS: 'Bewerk gebruiker uren',
    MODIFY_USER_HOURS_DAY: 'Bewerk gebruiker uren dag',
    OPTION_DISABLED: 'Uitgeschakeld',
    PASSWORD_CHANGE: 'Wachtwoord wijzigen',
    PASSWORD_RESET: 'Wachtwoord resetten',
    PASSWORD_RESET_TOKEN: 'Wachtwoord reset',
    FINALIZE_PAYMENT: 'Betaling afronden',
    PROJECT_CREATE: 'Project aanmaken',
    PROJECT_OVERVIEW: 'Projecten',
    PROJECT_DETAIL: 'Project',
    PROJECT_DETAIL_EDIT_PROJECT_NAME: 'Project - bewerk titel',
    PROJECT_DETAIL_EDIT_PROJECT_INFORMATION:"Project - bewerk project informatie",
    PROJECT_DETAIL_EDIT_PROJECT_CLIENT:"Project - bewerk gekoppelde klant",
    PROJECT_DETAIL_EDIT_CONTACT_INFORMATION:"Project - bewerk contact informatie",
    PROJECT_DETAIL_EDIT_IMAGES:"Project - bewerk foto's",
    PROJECT_DETAIL_EDIT_FUNCTIONAL_ID: 'Bewerk project id',
    REGISTER_HOURS_OVERVIEW: 'Registratie uren teamleden',
    PAYMENT: 'Betaalpagina',
    PLANNING: 'Planning',
    TASK_OVERVIEW: 'Taken',
    TASK_DETAIL: 'Taak detail',
    TASK_CREATE: 'Taak aanmaken',
    TASK_OVERVIEW_TASK_CREATE: 'Taak aanmaken',
    TASK_DETAIL_EDIT_TASK_NAME: 'Bewerk taaknaam',
    TASK_DETAIL_EDIT_TASK_STATUS: 'Bewerk taakstatus',
    TASK_DETAIL_EDIT_FUNCTIONAL_ID: 'Bewerk taak id',
    TASK_DETAIL_EDIT_WORK_SCHEMA: 'Bewerk werkschema',
    TASK_DETAIL_EDIT_TASK_INFORMATION: 'Bewerk taakinformatie',
    TASK_DETAIL_EDIT_TASK_RECURRENCE: 'Bewerk herhalings details',
    ROOT: 'root',
    SETTINGS: 'Instellingen',
    SETTINGS_LICENSE: 'Licentie',
    SETTINGS_LOCATION: 'Locatie Registratie',
    SETTINGS_WORK_SCHEDULES_PARENT: 'Werkroosters',
    SETTINGS_WORK_SCHEDULES: 'Werkroosters overzicht',
    SETTINGS_PARAMETERS: 'Parameters',
    SETTINGS_ORGANIZATION: 'Bedrijfsinformatie',
    SETTINGS_INVOICING_PARENT: 'Facturatie',
    SETTINGS_INVOICING_CONFIG_OVERVIEW: 'Facturatie configuratie overzicht',
    SETTINGS_INVOICING_CONFIG_EDIT: 'Facturatieconfiguratie aanmaken',
    SETTINGS_SUBORGANIZATION_PARENT: 'Suborganisaties', 
    SETTINGS_SUBORGANIZATION_CREATE: 'Suborganisatie aanmaken', 
    SETTINGS_SUBORGANIZATION_DETAIL_EDIT_INFORMATION: 'Bewerk suborganisatie', 
    SETTINGS_SUBORGANIZATION_DETAIL_EDIT_LOGO: 'Bewerk suborganisatie logo', 
    SETTINGS_SUBORGANIZATION_DETAIL_EDIT_NAME: 'Bewerk suborganisatie naam', 
    TEAM_DETAIL: 'Team detail',
    // TODO: BB-1248: delete TEAM_DETAIL_TEMP
    TEAM_DETAIL_TEMP:'Team detail temp',
    TEAM_DETAIL_ADD_PROJECT: 'Team - Project toevoegen',
    TEAM_DETAIL_ADD_MEMBER: 'Team - Teamlid toevoegen',
    TEAM_DETAIL_ADD_LEADER: 'Team - Ploegleider toevoegen',
    TEAM_DETAIL_EDIT_TEAM_NAME:'Team detail edit team name',
    TEAM_CLIENT_OVERVIEW: 'Team klanten',
    TEAM_CREATE: 'Team aanmaken',
    TEAM_OVERVIEW: 'Teams',
    TEAM_PROJECT_OVERVIEW: 'Team projecten',
    TEAM_CALENDAR_DETAIL_USER_EDIT_ABSENCE:'Team - bewerk afwezigheid gebruiker',
    TEAM_CALENDAR_DETAIL_USER_EDIT_HOURS:'Team - bewerk uren gebruiker',
    TEAM_MODIFY_USER_HOURS:'Team',
    TEAM_MODIFY_USER_HOURS_DAY:'Team - bewerk gebruiker uren dag',
    TRIAL: 'Demo aanvragen',
    TRIAL_TO_PAID: 'Proef naar betaald',
    USER_CREATE: 'Werknemer aanmaken',
    USER_DETAIL: 'Werknemer',
    USER_DETAIL_EDIT_AVATAR: 'Werknemer - bewerk avatar',
    USER_DETAIL_EDIT_CONTACT_INFORMATION: 'Werknemer - bewerk contact informatie',
    USER_DETAIL_EDIT_JOB_TITLE: 'Werknemer - bewerk functie',
    USER_DETAIL_EDIT_NAME: 'Werknemer - bewerk naam',
    USER_DETAIL_EDIT_PERSONAL_INFORMATION: 'Werknemer - bewerk persoonlijke informatie',
    USER_DETAIL_EDIT_STATUTE: 'Werknemer - bewerk statuut',
    USER_DETAIL_EDIT_WORK_SCHEDULE: 'Werknemer - bewerk werkrooster',
    USER_DETAIL_EDIT_ROLE: 'Werknemer - bewerk rol',
    USER_EDIT_BASIC: 'Werknemer - Basisinformatie',
    USER_CALENDAR_DETAIL: 'Werknemer - Pricking detail',
    USER_OVERVIEW: 'Werknemers',
    USER_TEAM_OVERVIEW: 'Team leden',
    WORK_SCHEDULE_DETAILS: 'Werkrooster info',
    MAINTENANCE_MODE: 'Onderhoud',
}
